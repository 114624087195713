import React from "react";
import { useOverrides } from "@quarkly/components";
import { Button, Box } from "@quarkly/widgets";
import { motion } from 'framer-motion';
import atomize from "@quarkly/atomize";
import { scrollToId } from './UxUtils';
const MotionButton = motion(Button);
const defaultProps = {
	"min-width": "100px",
	"min-height": "100px",
	"padding": "50px 0px 0px 0px",
	"width": "100%",
	"text-align": "center",
	buttonTarget: "#"
};
const overrides = {
	"button": {
		"kind": "Button",
		"props": {}
	}
};

const CtAbutton = props => {
	const {
		override,
		children,
		rest
	} = useOverrides(props, overrides, defaultProps);
	const hoverVariants = {
		hover: {
			scale: 1.15,
			transition: {
				duration: 0.2 //yoyo: Infinity // This will create the waving effect

			}
		}
	};
	const clickVariants = {
		click: {
			scale: [1, 1.2, 1],
			opacity: [1, 0.8, 1],
			transition: {
				duration: 0.6
			}
		}
	};
	return <Box {...rest}>
		      
		<MotionButton
			{...override("button")}
			variants={{ ...hoverVariants,
				...clickVariants
			}}
			whileHover="hover"
			whileTap="click"
			onClick={() => scrollToId(props.buttonTarget, props.scrollDuration, props.delay)}
		/>
		      
		{children}
		    
	</Box>;
};

const propInfo = {
	buttonTarget: {
		title: "Button's target",
		type: "text",
		category: "Main"
	},
	scrollDuration: {
		title: "Scroll duration in ms",
		type: "number",
		category: "Main"
	},
	delay: {
		title: "Delay animations by ms",
		type: "number",
		category: "Main"
	}
};
export default atomize(CtAbutton)({
	name: "CtAbutton",
	effects: {
		hover: ":hover",
		click: ":active"
	},
	normalize: true,
	mixins: true,
	description: {
		en: "CtAbutton — a call to action button with hover and click animations"
	},
	propInfo,
	defaultProps
});