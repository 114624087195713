import React, { useState, useEffect } from "react";
import { useOverrides } from "@quarkly/components";
import { Text, Box, Icon, Image } from "@quarkly/widgets";
import atomize from "@quarkly/atomize";
import { motion, AnimatePresence, useCycle } from "framer-motion";
import { IoIosArrowUp } from "react-icons/io";
const AccHeader = motion.header;
const AccBody = motion.section;
const MotionBox = motion(Box);
const defaultProps = {
	"min-width": "100px",
	"min-height": "100px",
	"quarkly-title": "SingleAccordion"
};
const overrides = {
	"Acc Header": {
		"kind": "Box",
		"props": {
			"min-width": "100px",
			"min-height": "100px",
			"quarkly-title": "AccordionHeader",
			"cursor": "pointer"
		}
	},
	"Acc Title": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 0px 0px",
			"children": "Some text"
		}
	},
	"Acc Body": {
		"kind": "Box",
		"props": {
			"min-width": "100px",
			"min-height": "100px",
			"quarkly-title": "AccordionBody"
		}
	},
	"AH Group Wrapper": {
		"props": {
			"display": "flex"
		}
	},
	"AH Group 1": {
		"props": {
			"width": "70%",
			"padding": "16px 16px 0 16px"
		}
	},
	"AH Group 2": {
		"props": {
			"width": "30%",
			"background": "rgba(0, 0, 0, 0) url(https://uploads.quarkly.io/65e4858a867c86001f1cafb1/images/Untitled-1.png?v=2024-06-27T18:26:25.942Z) center/contain no-repeat scroll padding-box",
			"margin": "16px 16px 16px 16px"
		}
	},
	"read more": {
		"props": {
			"text-align": "center",
			"font": "--base",
			"margin": "0 0px 0 0px",
			"children": "read more"
		}
	},
	"Icon Wrapper": {
		"props": {
			"display": "flex",
			"justify-content": "center",
			"align-items": "center",
			"background": "--color-lightD1",
			"border-radius": "0 0 15px 15px"
		}
	},
	"Acc Open Ico": {
		"props": {
			"category": "io",
			"icon": IoIosArrowUp,
			"transform": "scaleX(2)",
			"color": "--ctaRed"
		}
	}
};
const propInfo = {};

const SingleAccordion = ({ ...props
}) => {
	const {
		override,
		children,
		rest
	} = useOverrides(props, overrides, defaultProps);
	const [expanded, setExpanded] = useState(false);
	const [animate, cycle] = useCycle({
		scale: 1,
		rotateX: 0
	}, {
		scale: 1,
		rotateX: 180
	});
	useEffect(() => {
		cycle();
	}, [expanded]);
	return <Box style={{}} {...rest}>
		            
		<AccHeader
			style={{
				borderRadius: "15px"
			}}
			initial={false}
			animate={{
				backgroundColor: expanded ? "#F7FBFF" : "#ffffff" //border: expanded ? "1px solid #E4E8EC" : "transparent",

			}}
			onClick={() => setExpanded(!expanded)}
			{...override("Acc Header")}
		>
			<Box {...override('AH Group Wrapper')}>
				<Box {...override('AH Group 1')}>
					<Text {...override("Acc Title")} />
					<Text {...override("Acc Description")} />
					<Text {...override("Acc Price")} />
					                
				</Box>
				<Box {...override('AH Group 2')} />
				            
			</Box>
			<Text {...override('read more')} />
			<Box {...override('Icon Wrapper')}>
				<MotionBox animate={animate}>
					<Icon {...override('Acc Open Ico')} />
					          
				</MotionBox>
				 
			</Box>
			            
		</AccHeader>
		    
		<AnimatePresence initial={false}>
			{expanded && <AccBody
				key="content"
				initial="collapsed"
				animate="open"
				exit="collapsed"
				variants={{
					open: {
						opacity: 1,
						height: "auto"
					},
					collapsed: {
						opacity: 0,
						height: 0
					}
				}}
				transition={{
					duration: 0.8,
					ease: [0.04, 0.62, 0.23, 0.98]
				}}
				{...override("Acc Body")}
			>
				              
				{children}
				           
			</AccBody>}
		</AnimatePresence>
	</Box>;
};

export default atomize(SingleAccordion)({
	name: "SingleAccordion",
	description: {
		en: "SingleAccordion — my awesome component"
	},
	effects: {
		hover: ":hover"
	},
	normalize: true,
	mixins: true,
	propInfo: { ...propInfo
	}
});