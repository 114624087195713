import React, { useState } from "react";
import { useOverrides } from "@quarkly/components";
import { Box, Section } from "@quarkly/widgets";
import { motion, AnimatePresence } from "framer-motion";
import atomize from "@quarkly/atomize";
import TabContent1 from "./TabContent1";
import TabContent2 from "./TabContent2";
import TabContent3 from "./TabContent3";
const MotionBox = motion(Box);
const defaultProps = {
	display: "flex",
	flexDirection: "column",
	alignItems: "center",
	justifyContent: "center",
	padding: "16px"
};
const overrides = {
	nav: {
		kind: "Box",
		props: {
			as: "nav",
			display: "flex",
			marginBottom: "16px"
		}
	},
	ul: {
		kind: "Box",
		props: {
			as: "",
			display: "flex",
			padding: "0",
			margin: "0",
			listStyleType: "none",
			"border-radius": "15px 15px 0 0 ",
			"sm-display": "grid",
			"color": "--primaryBlack",
			"opacity": "0.8",
			"sm-grid-gap": "16px"
		}
	},
	li: {
		kind: "Box",
		props: {
			as: "",
			padding: "8px 16px",
			cursor: "pointer",
			position: "relative"
		}
	},
	selectedLi: {
		kind: "Box",
		props: {
			as: "",
			padding: "8px 16px",
			cursor: "pointer",
			position: "relative",
			border: "0 0 2px 0 solid #000"
		}
	},
	tabContent: {
		kind: "Box",
		props: {
			width: "100%",
			minHeight: "200px",
			flexDirection: "column",
			alignItems: "center",
			justifyContent: "center",
			"margin": "0px auto 0px auto",
			"border-radius": "15px",
			"display": "grid",
			"justify-content": "center",
			"padding": "0 0px 0 0px",
			"max-width": "max-content",
			"opacity": "0.9",
			"border-width": "1px",
			"border-style": "solid",
			"border-color": "--color-lightD2",
			"min-height": "none"
		}
	},
	"SelectedUnderline": {
		"props": {
			"sm-border-radius": "15px",
			"sm-box-shadow": "--l",
			"height": "2px",
			"position": "absolute",
			"bottom": 0,
			"left": 0,
			"right": 0,
			"width": "100%",
			"background": "#2A9D8F",
			"sm-height": "100%",
			"sm-width": "3px"
		}
	}
};

const TabsComponent = props => {
	const {
		override,
		rest
	} = useOverrides(props, overrides, defaultProps);
	const [selectedTab, setSelectedTab] = useState(0);
	const [isAnimating, setIsAnimating] = useState(true);
	const [prevIndex, setPrevIndex] = useState(0);
	const tabContents = [{
		label: "Custom Starter Websites",
		content: <TabContent1 {...override("tabContent")} />
	}, {
		label: "Comprehensive Website Management",
		content: <TabContent2 {...override("tabContent")} />
	}, {
		label: "Advanced Custom Features",
		content: <TabContent3 {...override("tabContent")} />
	}];
	const stiffness = 350; // Higher stiffness for a more springy effect

	const damping = 20; // Lower damping to allow more oscillation for bounce

	const duration = 0.3;
	const variants = {
		initial: direction => ({
			opacity: 0,
			x: direction === 'right' ? "50%" : "-50%"
		}),
		animate: {
			opacity: 1,
			x: 0,
			transition: {
				type: "spring",
				stiffness: stiffness,
				damping: damping,
				duration: duration
			}
		},
		exit: direction => ({
			opacity: 0,
			x: direction === 'right' ? "-50%" : "50%",
			transition: {
				duration: duration
			}
		})
	};

	const handleSelectTab = index => {
		setPrevIndex(selectedTab);
		setSelectedTab(index);
		setIsAnimating(false);
		setTimeout(() => setIsAnimating(true), 0);
	};

	const direction = selectedTab > prevIndex ? 'right' : 'left';
	return <Section {...rest}>
		<Box {...override("nav")}>
			<Box {...override("ul")}>
				{tabContents.map(({
					label
				}, index) => <Box key={label} onClick={() => handleSelectTab(index)} {...override(index === selectedTab ? "selectedLi" : "li")}>
					{label}
					{index === selectedTab ? <MotionBox layoutId="underline"
					/*style={{
     height: "2px",
     background: "#3A3FA7",
     width: "100%",
     position: "absolute",
     bottom: "0"
     }} */
					{...override('SelectedUnderline')} /> : null}
				</Box>)}
			</Box>
		</Box>
		<AnimatePresence exitBeforeEnter>
			<MotionBox
				key={selectedTab}
				initial="initial"
				animate="animate"
				exit="exit"
				variants={variants}
				transition={{
					duration: 0.4,
					ease: "easeInOut"
				}}
				custom={direction}
			>
				<Box {...override("tabContent")}>
					{tabContents.map(({
						content
					}, index) => index === selectedTab && <Box key={index}>
						{content}
					</Box>)}
				</Box>
			</MotionBox>
		</AnimatePresence>
	</Section>;
};

export default atomize(TabsComponent)({
	name: "TabsComponent",
	effects: {
		hover: ":hover"
	},
	normalize: true,
	mixins: true,
	description: {
		en: "TabsComponent — a tabbed navigation component with animated transitions"
	},
	propInfo: {}
});