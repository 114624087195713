import React, { useEffect, useState } from "react";
import { useOverrides, Override, SocialMedia } from "@quarkly/components";
import { Image, Text, LinkBox, Box, Link, Section, Button } from "@quarkly/widgets";
import QuarklycommunityKitMenu from "./QuarklycommunityKitMenu";
import QuarklycommunityKitMobileSidePanel from "./QuarklycommunityKitMobileSidePanel";
import { motion, useScroll, useVelocity } from "framer-motion";
import { scrollToId } from './UxUtils';
import { FiMenu } from "react-icons/fi";
import { AiOutlineMenu } from "react-icons/ai";
const MotionSection = motion(Section);
const defaultProps = {
	"sm-padding": "8px 0 8px 0",
	"quarkly-title": "TopNavBar",
	"position": "fixed",
	"z-index": "1",
	"padding": "12px 0px 12px 0px",
	"box-shadow": "--m",
	"background": "rgba(247, 251, 255, 0.8)",
	"lg-bottom": "auto",
	"lg-height": "80px",
	"lg-top": "0px",
	"lg-left": 0,
	"lg-right": 0,
	"top": 0
};
const overrides = {
	"box": {
		"kind": "Box",
		"props": {
			"display": "flex",
			"padding": "12px 0",
			"justify-content": "space-between",
			"align-items": "flex-start",
			"flex-direction": "row",
			"width": "30%",
			"sm-width": "50%",
			"sm-align-items": "center",
			"sm-flex-direction": "row",
			"sm-justify-content": "flex-start",
			"md-width": "50%",
			"lg-width": "70%",
			"md-justify-content": "flex-start"
		}
	},
	"linkBox": {
		"kind": "LinkBox",
		"props": {
			"flex-direction": "row",
			"href": "/index",
			"display": "flex",
			"grid-gap": "12px",
			"padding": "0px 16px 0px 0px",
			"position": "absolute",
			"left": "14px",
			"top": "14px",
			"opacity": "0.6"
		}
	},
	"image": {
		"kind": "Image",
		"props": {
			"display": "block",
			"width": "50px",
			"height": "50px",
			"position": "relative",
			"filter": "none",
			"src": "https://uploads.quarkly.io/65e4858a867c86001f1cafb1/images/favicon.png?v=2024-07-01T17:54:05.854Z"
		}
	},
	"text": {
		"kind": "Text",
		"props": {
			"margin": "0",
			"md-margin": "0px 0 0px 0",
			"text-align": "left",
			"sm-margin": "0px 0 0px 0",
			"display": "block",
			"color": "--primaryBlack",
			"font": "--lead",
			"children": "WAVEDROPPER"
		}
	},
	"quarklycommunityKitMobileSidePanel": {
		"kind": "QuarklycommunityKitMobileSidePanel",
		"props": {
			"menuPosition": "full",
			"breakpoint": "lg",
			"width": "70%",
			"sm-width": "50%",
			"md-width": "50%",
			"lg-width": "30%",
			"animFunction": "ease-in-out",
			"onloadShow": false
		},
		"overrides": {
			"Wrapper": {
				"props": {}
			}
		}
	},
	"quarklycommunityKitMobileSidePanelOverride": {
		"kind": "Override",
		"props": {
			"slot": "Children",
			"md-display": "flex"
		}
	},
	"quarklycommunityKitMobileSidePanelOverride1": {
		"kind": "Override",
		"props": {
			"slot": "Content",
			"padding": "0px 0px 0px 0px",
			"background": "rgba(255, 255, 255, 0)",
			"lg-background": "#ffffff",
			"lg-margin": "0px 0px 0px 0px"
		}
	},
	"quarklycommunityKitMobileSidePanelOverride2": {
		"kind": "Override",
		"props": {
			"slot": "Button Text",
			"font": "normal 600 16px/1.5 -apple-system, system-ui, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif",
			"text-transform": "uppercase",
			"letter-spacing": "1px",
			"sm-font": "normal 600 14px/1.5 -apple-system, system-ui, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif",
			"sm-margin": "0px 2px 0px 0px",
			"lg-margin": "0px 0px 0px 0px"
		}
	},
	"quarklycommunityKitMobileSidePanelOverride3": {
		"kind": "Override",
		"props": {
			"slot": "Button Icon :closed",
			"category": "fi",
			"icon": FiMenu,
			"size": "32px",
			"padding": "5px 7px 5px 7px",
			"border-radius": "50px"
		}
	},
	"quarklycommunityKitMobileSidePanelOverride4": {
		"kind": "Override",
		"props": {
			"slot": "Button Icon",
			"width": "28px",
			"height": "28px",
			"category": "ai",
			"icon": AiOutlineMenu,
			"color": "--dark",
			"size": "24px",
			"lg-width": "32px",
			"lg-height": "32px"
		}
	},
	"quarklycommunityKitMobileSidePanelOverride5": {
		"kind": "Override",
		"props": {
			"slot": "Cross",
			"lg-width": "32px",
			"lg-height": "32px",
			"size": "32px",
			"top": "24px",
			"right": "24px",
			"id": "closemenu"
		}
	},
	"box1": {
		"kind": "Box",
		"props": {
			"align-items": "center",
			"lg-justify-content": "center",
			"lg-align-items": "flex-start",
			"justify-content": "flex-end",
			"display": "flex",
			"lg-flex-direction": "column",
			"lg-margin": "0px auto 0px auto",
			"lg-min-width": "300px",
			"lg-max-width": "1280px",
			"lg-width": "90%",
			"lg-padding": "24px 0px 48px 0px"
		}
	},
	"box2": {
		"kind": "Box",
		"props": {
			"display": "none",
			"lg-width": "100%",
			"lg-margin": "0px 0px 24px 0px",
			"lg-display": "flex",
			"lg-padding": "12px 0px 12px 0px"
		}
	},
	"linkBox1": {
		"kind": "LinkBox",
		"props": {
			"flex-direction": "row",
			"href": "/index",
			"display": "flex",
			"grid-gap": "12px"
		}
	},
	"image1": {
		"kind": "Image",
		"props": {
			"display": "block",
			"width": "36px",
			"height": "36px",
			"src": "https://uploads.quarkly.io/65e4858a867c86001f1cafb1/images/favicon.png?v=2024-07-01T17:54:05.854Z",
			"sm-filter": "none"
		}
	},
	"text1": {
		"kind": "Text",
		"props": {
			"margin": "0",
			"md-margin": "0px 0 0px 0",
			"text-align": "left",
			"font": "--lead",
			"sm-margin": "0px 0 0px 0",
			"display": "block",
			"lg-font": "--lead",
			"children": "Wavedropper"
		}
	},
	"quarklycommunityKitMenu": {
		"kind": "QuarklycommunityKitMenu",
		"props": {
			"display": "flex",
			"filterMode": "exclude",
			"filterPages": "/index,/terms,/cookies,/privacy",
			"grid-gap": "12px",
			"lg-flex-direction": "column",
			"lg-padding": "6px 0px 6px 0px",
			"lg-margin": "0px 0px 24px 0px",
			"align-items": "center",
			"flex-wrap": "wrap",
			"overflow-x": "visible",
			"overflow-y": "visible",
			"lg-align-items": "flex-start",
			"md-align-self": "center",
			"md-margin": "30px 0px 24px 0px",
			"lg-align-self": "center"
		}
	},
	"quarklycommunityKitMenuOverride": {
		"kind": "Override",
		"props": {
			"slot": "link",
			"color": "--darkL2",
			"hover-color": "--primary",
			"font": "--base",
			"text-decoration-line": "initial",
			"transition": "color 0.1s ease 0s",
			"lg-font": "--lead"
		}
	},
	"quarklycommunityKitMenuOverride1": {
		"kind": "Override",
		"props": {
			"slot": "item-active",
			"border-width": "0 0 2px 0",
			"border-style": "solid",
			"border-color": "--color-darkL2",
			"lg-border-width": "0 0 0 2px"
		}
	},
	"quarklycommunityKitMenuOverride2": {
		"kind": "Override",
		"props": {
			"slot": "item",
			"padding": "6px 6px 6px 6px",
			"lg-padding": "6px 12px 6px 12px"
		}
	},
	"quarklycommunityKitMenuOverride3": {
		"kind": "Override",
		"props": {
			"slot": "link-active",
			"cursor": "default",
			"color": "--darkL2",
			"hover-color": "--darkL2"
		}
	},
	"quarklycommunityKitMenuOverride4": {
		"kind": "Override",
		"props": {
			"slot": "link-about"
		}
	},
	"quarklycommunityKitMenuOverride5": {
		"kind": "Override",
		"props": {
			"slot": "link-about-us",
			"color": "--primaryBlack",
			"hover-color": "--accent",
			"font": "--lead"
		}
	},
	"link": {
		"kind": "Link",
		"props": {
			"font": "--base",
			"text-decoration-line": "initial",
			"margin": "0px 0px 0px 16px",
			"background": "--color-primaryBlack",
			"border-radius": "8px",
			"color": "#ffffff",
			"padding": "6px 12px 7px 12px",
			"lg-margin": "0px 0px 24px 0px",
			"lg-padding": "12px 18px 13px 18px",
			"lg-font": "--lead",
			"display": "flex",
			"justify-content": "center",
			"align-items": "center",
			"opacity": "1",
			"md-align-self": "center",
			"lg-align-self": "center",
			"children": "Contact"
		}
	},
	"box3": {
		"kind": "Box",
		"props": {
			"width": "25%",
			"display": "none",
			"lg-width": "100%",
			"lg-flex-direction": "column",
			"lg-align-items": "flex-start",
			"justify-content": "space-around",
			"align-items": "center",
			"flex-wrap": "wrap",
			"lg-margin": "32px 0px 0px 0px",
			"sm-display": "none",
			"md-display": "none",
			"lg-display": "none"
		}
	},
	"socialMedia": {
		"kind": "SocialMedia",
		"props": {
			"instagram": "https://instagram.com/instagram",
			"margin": "0px 0px 0px 0px",
			"facebook": "https://www.facebook.com/quarklyapp/",
			"youtube": "https://www.youtube.com/channel/UCK5bXs2L0bbSMQ82BQ3hIkw",
			"lg-display": "flex",
			"lg-grid-gap": "12px"
		}
	},
	"socialMediaOverride": {
		"kind": "Override",
		"props": {
			"slot": "link-twitter",
			"margin": "0px 0px 0px 5px",
			"children": <div />
		}
	},
	"socialMediaOverride1": {
		"kind": "Override",
		"props": {
			"slot": "link",
			"border-radius": "50%",
			"background": "transparent",
			"hover-color": "--light",
			"display": "flex",
			"margin": "0 5px 0 5px",
			"padding": "5x 5px 5px 5px",
			"width": "32px",
			"height": "32px",
			"align-items": "center",
			"justify-content": "center"
		}
	},
	"socialMediaOverride2": {
		"kind": "Override",
		"props": {
			"slot": "icon",
			"size": "32px",
			"border-radius": "50px",
			"color": "--grey"
		}
	},
	"socialMediaOverride3": {
		"kind": "Override",
		"props": {
			"slot": "link-facebook",
			"margin": "0px 5px 0px 0px",
			"children": <div />
		}
	},
	"SectionContent": {
		"props": {}
	}
};

const TopNavBar = props => {
	const {
		override,
		children,
		rest
	} = useOverrides(props, overrides, defaultProps);

	const simulateClick = id => {
		const element = document.getElementById(id);

		if (element) {
			element.click();
		}
	};

	const slideDistance = 80; // if we are sliding out a nav bar at the top of the screen, this will be it's height

	const threshold = 200; // only slide it back when scrolling back at velocity above this positive (or zero) value

	const {
		scrollY
	} = useScroll();
	const scrollVelocity = useVelocity(scrollY);
	const [isScrollingBack, setIsScrollingBack] = useState(false);
	const [isAtTop, setIsAtTop] = useState(true); // true if the page is not scrolled or fully scrolled back

	const [isInView, setIsInView] = useState(true);
	useEffect(() => scrollVelocity.onChange(latest => {
		if (latest > 0) {
			setIsScrollingBack(false);
			return;
		}

		if (latest < -threshold) {
			setIsScrollingBack(true);
			return;
		}
	}), []);
	useEffect(() => scrollY.onChange(latest => setIsAtTop(latest <= 0)), []);
	useEffect(() => setIsInView(isScrollingBack || isAtTop), [isScrollingBack, isAtTop]);

	const handleContactButtonClick = () => {
		simulateClick('closemenu');
		setTimeout(() => {
			scrollToId('contactform', 500, 0);
		}, 100); // Delay to ensure the menu closes first
	};

	return <MotionSection
		id="top-navbar"
		animate={{
			y: isInView ? 0 : -slideDistance
		}}
		transition={{
			duration: 0.2,
			delay: 0.25,
			ease: "easeInOut"
		}}
		style={{
			height: slideDistance
		}}
		{...rest}
	>
		<Override slot="SectionContent" flex-direction="row" justify-content="space-between" />
		<Box {...override("box")}>
			<LinkBox {...override("linkBox")}>
				<Image {...override("image")} />
				<Text {...override("text")} />
			</LinkBox>
		</Box>
		<QuarklycommunityKitMobileSidePanel {...override("quarklycommunityKitMobileSidePanel")}>
			<Override {...override("quarklycommunityKitMobileSidePanelOverride")} />
			<Override {...override("quarklycommunityKitMobileSidePanelOverride1")} />
			<Override {...override("quarklycommunityKitMobileSidePanelOverride2")} />
			<Override {...override("quarklycommunityKitMobileSidePanelOverride3")} />
			<Override {...override("quarklycommunityKitMobileSidePanelOverride4")} />
			<Override {...override("quarklycommunityKitMobileSidePanelOverride5")} />
			<Box {...override("box1")}>
				<Box {...override("box2")}>
					<LinkBox {...override("linkBox1")}>
						<Image {...override("image1")} />
						<Text {...override("text1")} />
					</LinkBox>
				</Box>
				<QuarklycommunityKitMenu {...override("quarklycommunityKitMenu")}>
					<Override {...override("quarklycommunityKitMenuOverride")} />
					<Override {...override("quarklycommunityKitMenuOverride1")} />
					<Override {...override("quarklycommunityKitMenuOverride2")} />
					<Override {...override("quarklycommunityKitMenuOverride3")} />
					<Override {...override("quarklycommunityKitMenuOverride4")} />
					<Override {...override("quarklycommunityKitMenuOverride5")} />
				</QuarklycommunityKitMenu>
				<Button {...override("link")} onClick={() => handleContactButtonClick()} />
				<Box {...override("box3")}>
					<SocialMedia {...override("socialMedia")}>
						<Override {...override("socialMediaOverride")} />
						<Override {...override("socialMediaOverride1")} />
						<Override {...override("socialMediaOverride2")} />
						<Override {...override("socialMediaOverride3")} />
					</SocialMedia>
				</Box>
			</Box>
		</QuarklycommunityKitMobileSidePanel>
		{children}
	</MotionSection>;
};

const propInfo = {};
Object.assign(TopNavBar, { ...Section,
	defaultProps,
	overrides,
	propInfo
});
export default TopNavBar;