import React, { useRef } from 'react';
import { useOverrides } from '@quarkly/components';
import { Section, Box } from '@quarkly/widgets';
import { motion, useScroll, useTransform, useSpring } from 'framer-motion';
import atomize from '@quarkly/atomize';

const gradient = mask => `conic-gradient(black 0%, black ${mask ? 0 : 100}%, transparent ${mask ? 0 : 101}%, transparent 100%)`;

const defaultProps = {
	background: "rgba(0, 0, 0, 0) linear-gradient(0deg,rgba(0,0,0,0) 57.1%,--color-primaryBlue 100%) 0% 0% /auto repeat scroll padding-box"
};
const overrides = {
	ImageWrapper: {
		kind: 'Box',
		props: {
			"max-width": "600px",
			"margin": "0px auto",
			"display": "grid",
			"align-items": "center",
			"justify-items": "center",
			"min-height": "300px",
			"background": "#ffffff"
		}
	},
	Image: {
		kind: 'Box',
		props: {
			"width": "100%",
			"height": "100%",
			"background-size": "cover",
			"background-position": "center"
		}
	}
};

const AnimatedBox = ({
	children,
	...props
}) => {
	const target = useRef(null);
	const {
		scrollYProgress
	} = useScroll({
		target,
		offset: [[0, 0.75], [0.5, 0.6]]
	});
	const smoothed = useSpring(scrollYProgress, {
		damping: 30,
		stiffness: 400,
		restDelta: 0.001
	});
	const maskImage = useTransform(smoothed, [0, 1], [gradient(true), gradient(false)]);
	return <motion.div ref={target} style={{
		WebkitMaskImage: maskImage,
		maskImage,
		overflow: 'hidden',
		margin: '20px 0'
	}}>
		<Box {...props}>
			{children}
		</Box>
	</motion.div>;
};

const SingleImageContainer = ({
	imageUrl,
	...props
}) => {
	const {
		override,
		rest
	} = useOverrides(props, overrides, defaultProps);
	return <Section {...rest}>
		<AnimatedBox {...override('ImageWrapper')}>
			<Box {...override('Image')} style={{
				backgroundImage: `url(${imageUrl})`
			}} />
		</AnimatedBox>
	</Section>;
};

export default atomize(SingleImageContainer)({
	name: 'SingleImageContainer',
	effects: {
		hover: ':hover'
	},
	normalize: true,
	mixins: true,
	description: {
		en: 'SingleImageContainer — a component that renders an animated image.'
	},
	propInfo: {
		imageUrl: {
			title: 'Image URL',
			control: 'image',
			type: 'string',
			category: 'Main',
			weight: 1
		}
	},
	defaultProps: {
		imageUrl: 'https://placekitten.com/600/400'
	}
});