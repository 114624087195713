import React from "react";
import { useOverrides } from "@quarkly/components";
import { Box, Text, List, Link, Image } from "@quarkly/widgets";
import CtAbutton from './CtAbutton';
const overrides = {};

const TabContent2 = props => {
	const {
		override
	} = useOverrides(props, overrides);
	return <Box {...override("tabContent")}>
		      
		<Text {...override('Title-tab2')} />
		      
		<Box {...override('tab2-cwrapper')}>
			        
			<Box {...override('t2-group1')}>
				          
				<Text {...override('Subtitle1-tab2')} />
				          
				<List {...override('Service details-tab2')}>
					            
					<Text {...override('sd2li1')} />
					            
					<Text {...override('sd2li2')} />
					            
					<Text {...override('sd2li3')} />
					            
					<Text {...override('sd2li4')} />
					            
					<Text {...override('sd2li5')} />
					          
				</List>
				        
			</Box>
			        
			<Box {...override('t2-group2')}>
				          
				<Text {...override('Subtitle2-tab2')} />
				          
				<List {...override('Benefits')}>
					            
					<Text {...override('be2li1')} />
					            
					<Text {...override('be2li2')} />
					            
					<Text {...override('be2li3')} />
					            
					<Text {...override('be2li4')} />
					            
					<Text {...override('be2li5')} />
					            
					<Text {...override('be2li6')} />
					          
				</List>
				        
			</Box>
			      
		</Box>
		      
		<CtAbutton {...override('more-tab2')} />
		    
	</Box>;
};

export default TabContent2;