import React, { useState, useEffect, useCallback } from 'react';
import { useOverrides } from '@quarkly/components';
import { Box, Text, Icon } from '@quarkly/widgets';
import atomize from "@quarkly/atomize";
import { Reorder, AnimatePresence, motion } from "framer-motion";
const MotionBox = motion(Box);
const overrides = {};

const parsePrice = item => {
	const match = item.match(/ £(\d+) /);
	return match ? parseInt(match[1], 10) : 0;
};

const AddonList = ({
	full_list,
	base_price,
	...props
}) => {
	const {
		override,
		children,
		rest
	} = useOverrides(props, overrides);
	const initial_list = full_list ? full_list.split(',') : [];
	const [itemsColumn1, setItemsColumn1] = useState([]);
	const [itemsColumn2, setItemsColumn2] = useState(initial_list);
	const [totalPrice, setTotalPrice] = useState(0);
	const calculateTotalPrice = useCallback(() => {
		const total = itemsColumn1.reduce((acc, item) => acc + parsePrice(item), 0) + parseInt(base_price, 10);
		setTotalPrice(total);
	}, [itemsColumn1, base_price]);
	useEffect(() => {
		calculateTotalPrice();
	}, [itemsColumn1, base_price, calculateTotalPrice]);

	const moveToOtherColumn = (sourceItems, setSourceItems, targetItems, setTargetItems, item) => {
		setSourceItems(sourceItems.filter(i => i !== item));
		setTargetItems([...targetItems, item]);
	};

	const isMobile = () => {
		return /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
	};

	return <Box
		{...rest}
		display="flex"
		flexDirection="row"
		justifyContent="flex-start"
		alignItems="flex-start"
		gap="20px"
	>
		            
		{children}
		            
		<Box {...override('AddonsGrouping')}>
			                
			<Box {...override('Total Price Wrapper')}>
				                    
				{!isMobile() && <Text {...override('Price Disclaimer')} />}
				                    
				{isMobile() && <Text {...override('Price Disclaimer on Mobile')} />}
				                    
				<Box {...override('Price sub wrapper')}>
					                        
					<Text {...override('Total Price Label')}>
						Total: £
						{totalPrice}
					</Text>
					                        
					<Text {...override('price ext')} />
					                    
				</Box>
				                
			</Box>
			            
		</Box>
		            
		<Box display="flex" flexDirection="column" gap="20px">
			                
			<Box {...override('Column1Wrapper')}>
				                    
				<Text {...override('ColumnTitle1')} />
				                    
				<Reorder.Group
					values={itemsColumn1}
					onReorder={setItemsColumn1}
					axis="y"
					style={{
						listStyle: "none",
						padding: 0,
						margin: 0
					}}
					{...override('Reorder Group')}
				>
					                        
					<AnimatePresence initial={false} mode="popLayout">
						                            
						{itemsColumn1.map(item => <Reorder.Item
							{...override('Reorder Item')}
							key={item}
							value={item}
							style={{
								fontSize: 22,
								backgroundColor: "#ffffff",
								boxShadow: "0 20px 25px -5px rgba(0, 0, 0, 0.1)",
								borderRadius: "15px",
								padding: 6,
								paddingLeft: 14,
								marginBottom: 10,
								cursor: "grab",
								display: "flex",
								justifyContent: "space-between",
								alignItems: "center"
							}}
							whileTap={{
								cursor: "grabbing"
							}}
							whileDrag={{
								scale: 1.03,
								boxShadow: "5px 5px 10px rgba(0,0,0,0.3)"
							}}
							initial={{
								opacity: 0,
								y: 100
							}}
							animate={{
								opacity: 1,
								y: 0
							}}
							exit={{
								opacity: 0,
								x: 300
							}}
							drag={!isMobile()}
							/*dragConstraints={{
           left: 0,
           right: 0,
           top: 0,
           bottom: 0
       }}*/

							onDragEnd={() => moveToOtherColumn(itemsColumn1, setItemsColumn1, itemsColumn2, setItemsColumn2, item)}
						>
							                                    
							<Text font="--base">
								{item}
							</Text>
							                                    
							<MotionBox style={{
								width: 34,
								height: 34,
								cursor: "pointer",
								marginLeft: 10,
								display: "flex",
								justifyContent: "center",
								alignItems: "center"
							}} onTap={() => moveToOtherColumn(itemsColumn1, setItemsColumn1, itemsColumn2, setItemsColumn2, item)} {...override('Delete Button')}>
								                                        
								<Icon {...override('Remove Icon')} />
								                                    
							</MotionBox>
							                                
						</Reorder.Item>)}
						                        
					</AnimatePresence>
					                    
				</Reorder.Group>
				                
			</Box>
			            
		</Box>
		            
		<Box display="flex" flexDirection="column" gap="20px">
			                
			<Box {...override('Column2Wrapper')}>
				                    
				<Text {...override('ColumnTitle2')} />
				                    
				<Reorder.Group
					values={itemsColumn2}
					onReorder={setItemsColumn2}
					axis="y"
					style={{
						listStyle: "none",
						padding: 0,
						margin: 0
					}}
					{...override('Reorder Group 2')}
				>
					                        
					<AnimatePresence initial={false} mode="popLayout">
						                            
						{itemsColumn2.map(item => <Reorder.Item
							{...override('Reorder Item 2')}
							key={item}
							value={item}
							style={{
								fontSize: 22,
								backgroundColor: "#ffffff",
								boxShadow: "0 20px 25px -5px rgba(0, 0, 0, 0.1)",
								borderRadius: "15px",
								padding: 6,
								paddingLeft: 14,
								marginBottom: 10,
								cursor: "grab",
								display: "flex",
								justifyContent: "space-between",
								alignItems: "center"
							}}
							whileTap={{
								cursor: "grabbing"
							}}
							whileDrag={{
								scale: 1.03,
								boxShadow: "5px 5px 10px rgba(0,0,0,0.3)"
							}}
							initial={{
								opacity: 0,
								y: 100
							}}
							animate={{
								opacity: 1,
								y: 0
							}}
							exit={{
								opacity: 0,
								x: 300
							}}
							drag={!isMobile()}
							/*dragConstraints={{
           left: 0,
           right: 0,
           top: 0,
           bottom: 0
       }}*/

							onDragEnd={() => moveToOtherColumn(itemsColumn2, setItemsColumn2, itemsColumn1, setItemsColumn1, item)}
						>
							                                    
							<Text font="--base">
								{item}
							</Text>
							                                    
							<MotionBox style={{
								width: 34,
								height: 34,
								cursor: "pointer",
								marginLeft: 10,
								display: "flex",
								justifyContent: "center",
								alignItems: "center"
							}} onTap={() => moveToOtherColumn(itemsColumn2, setItemsColumn2, itemsColumn1, setItemsColumn1, item)} {...override('Add Button')}>
								                                        
								<Icon {...override('Add Icon')} />
								                                    
							</MotionBox>
							                                
						</Reorder.Item>)}
						                        
					</AnimatePresence>
					                    
				</Reorder.Group>
				                
			</Box>
			            
		</Box>
		        
	</Box>;
};

const propInfo = {
	full_list: {
		title: 'Full List',
		description: {
			en: 'List of all add-ons to be displayed and managed in the component'
		},
		control: 'input',
		multiply: true,
		category: 'Main',
		weight: 1
	},
	base_price: {
		title: 'Base Price',
		description: {
			en: 'The base price to be added to the total of item prices'
		},
		control: 'input',
		type: 'number',
		category: 'Main',
		weight: 1
	}
};
const defaultProps = {
	full_list: ["🐻 Well, yes, I cycle, but only in the circus.", "🐼 I’m a ‘giant bear cat’ in Chinese.", "🐰 I’m a rabbit, not a hare.", "🐨 Come visit the bush, mate!", "🐻‍❄️ A penguin? What’s a penguin?", "🐭 I’m a house mouse, not a computer mouse.", "🐶 No, I’m not a poodle.", "🐱 You think you can train me? Please try.", "🐹 I put stuff in my cheeks.", "🦊 I’m so foxy.", "🐯 I’m a cute little tiger", "🦁 I’m also cute. Come closer. £250 "],
	base_price: '100'
};
export default atomize(AddonList)({
	name: "Add-on List",
	effects: {
		hover: ":hover"
	},
	normalize: true,
	mixins: true,
	description: {
		en: "Add-on List — a component for displaying and managing a list of items with reorder and delete functionality"
	},
	propInfo,
	defaultProps
});