import React from 'react';
import { Box } from '@quarkly/widgets';

const PostmarkForm = ({
	children,
	name = 'contact',
	action = '/',
	onLoading,
	onSuccess,
	onError
}) => {
	const handleSubmit = async event => {
		event.preventDefault();
		if (onLoading) onLoading(true);
		if (onError) onError(false);
		if (onSuccess) onSuccess(false);
		const formData = new FormData(event.target);
		const data = Object.fromEntries(formData.entries());
		console.log(data.Text);

		try {
			const response = await fetch('https://wavedropper.com/.netlify/functions/sendRichEmailWithPostmark', {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json'
				},
				body: JSON.stringify({
					email: data.Email,
					// Assuming the form contains an email field
					content: {
						name: data.Name || 'No Name',
						subject: `Form Submission from ${data.Name} ${data.Email}`,
						text: data.Text || '',
						html: data.Text ? `<p>${data.Text}</p>` : ''
					}
				})
			});

			if (response.ok) {
				if (onSuccess) onSuccess(true);
			} else {
				throw new Error('Form submission failed');
			}
		} catch (error) {
			if (onError) onError(true);
		} finally {
			if (onLoading) onLoading(false);
		}
	};

	return <Box
		as="form"
		name={name}
		method="POST"
		action={action}
		onSubmit={handleSubmit}
	>
		            
		{children}
		        
	</Box>;
};

export default PostmarkForm;