import React from "react";
import { useOverrides } from "@quarkly/components";
import { Box, Text, List, Link, Image } from "@quarkly/widgets";
import CtAbutton from './CtAbutton';
const overrides = {};

const TabContent3 = props => {
	const {
		override
	} = useOverrides(props, overrides);
	return <Box {...override("tabContent")}>
		      
		<Text {...override('Title-tab3')} />
		      
		<Box {...override('t3-cwrapper')}>
			        
			<Box {...override('t3-group1')}>
				          
				<Text {...override('Subtitle1-tab3')} />
				          
				<List {...override('Service details-tab3')}>
					            
					<Text {...override('sd3li1')} />
					            
					<Text {...override('sd3li2')} />
					            
					<Text {...override('sd3li3')} />
					            
					<Text {...override('sd3li4')} />
					            
					<Text {...override('sd3li5')} />
					          
				</List>
				        
			</Box>
			        
			<Box {...override('t3-group2')}>
				          
				<Text {...override('Subtitle2-tab3')} />
				          
				<List {...override('Benefits3')}>
					            
					<Text {...override('be3li1')} />
					            
					<Text {...override('be3li2')} />
					            
					<Text {...override('be3li3')} />
					            
					<Text {...override('be3li4')} />
					            
					<Text {...override('be3li5')} />
					          
				</List>
				        
			</Box>
			      
		</Box>
		      
		<CtAbutton {...override('more-tab3')} />
		    
	</Box>;
};

export default TabContent3;