import React from "react";
import { useOverrides } from "@quarkly/components";
import { Box, Text, List, Link, Image } from "@quarkly/widgets";
import CtAbutton from './CtAbutton';
const overrides = {};

const TabContent1 = props => {
	const {
		override
	} = useOverrides(props, overrides);
	return <Box {...override("tabContent")}>
		      
		<Text {...override('Title-tab1')} />
		      
		<Box {...override('tab1-cwrapper')}>
			        
			<Box {...override('tab1-Group1')}>
				          
				<Text {...override('Subtitle1-tab1')} />
				          
				<List {...override('Service details')}>
					            
					<Text {...override('sdli1')} />
					            
					<Text {...override('sdli2')} />
					            
					<Text {...override('sdli3')} />
					            
					<Text {...override('sdli4')} />
					          
				</List>
				        
			</Box>
			        
			<Box {...override('tab1-Group2')}>
				          
				<Text {...override('Subtitle2-tab1')} />
				          
				<List {...override('Pricing options')}>
					            
					<Text {...override('poli1')} />
					            
					<Text {...override('poli2')} />
					          
				</List>
				        
			</Box>
			        
			<Box {...override('tab1-Group3')}>
				          
				<Text {...override('Subtitle3-tab1')} />
				          
				<List {...override('Benefits')}>
					            
					<Text {...override('beli1')} />
					            
					<Text {...override('beli2')} />
					            
					<Text {...override('beli3')} />
					            
					<Text {...override('beli4')} />
					            
					<Text {...override('beli5')} />
					          
				</List>
				        
			</Box>
			      
		</Box>
		      
		<CtAbutton {...override('more-tab1')} />
		    
	</Box>;
};

export default TabContent1;