import React, { useState } from "react";
import { useOverrides } from "@quarkly/components";
import { Text, Input, Button, Box } from "@quarkly/widgets";
import PostmarkForm from './PostmarkForm';
import { motion } from 'framer-motion';
const MotionButton = motion(Button);
const defaultProps = {
	"width": "100%"
};
const overrides = {
	"HeadingText": {
		"kind": "Text",
		"props": {
			"sm-text-align": "center",
			"margin": "0px 0px 8px 0px",
			"color": "--primaryBlack",
			"font": "--headline2",
			"children": "Contact Us"
		}
	},
	"SubHeadingText": {
		"kind": "Text",
		"props": {
			"sm-margin": "0px 0px 12px 0px",
			"sm-text-align": "center",
			"margin": "0px 0px 24px 0px",
			"text-align": "left",
			"font": "--lead",
			"color": "--accent",
			"children": "Get in touch with us today to discuss your future website."
		}
	},
	"text": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 16px 0px",
			"font": "--lead",
			"sm-margin": "0px 0px 8px 0px",
			"children": "Name",
			"color": "--primaryBlack",
			"display": "none"
		}
	},
	"input": {
		"kind": "Input",
		"props": {
			"required": true,
			"placeholder": "Your name",
			"border-radius": "4px",
			"border-style": "solid",
			"width": "100%",
			"name": "Name",
			"margin": "0px 0px 24px 0px",
			"background": "rgba(255, 255, 255, 0)",
			"border-color": "rgba(228, 232, 236, 0.25)",
			"border-width": "2px",
			"padding": "10px 16px 10px 16px",
			"type": "text",
			"sm-margin": "0px 0px 14px 0px",
			"color": "--primaryBlack"
		}
	},
	"text1": {
		"kind": "Text",
		"props": {
			"sm-margin": "0px 0px 8px 0px",
			"margin": "0px 0px 16px 0px",
			"font": "--lead",
			"color": "--lightD2",
			"children": "E-mail",
			"display": "none"
		}
	},
	"input1": {
		"kind": "Input",
		"props": {
			"required": true,
			"placeholder": "Your email address",
			"border-radius": "4px",
			"border-style": "solid",
			"width": "100%",
			"sm-margin": "0px 0px 14px 0px",
			"border-color": "rgba(228, 232, 236, 0.25)",
			"border-width": "2px",
			"padding": "10px 16px 10px 16px",
			"name": "Email",
			"type": "email",
			"margin": "0px 0px 24px 0px",
			"background": "rgba(255, 255, 255, 0)",
			"color": "--primaryBlack"
		}
	},
	"text2": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 16px 0px",
			"font": "--lead",
			"color": "--lightD2",
			"sm-margin": "0px 0px 8px 0px",
			"children": "Message",
			"display": "none"
		}
	},
	"input2": {
		"kind": "Input",
		"props": {
			"required": true,
			"placeholder": "Type your message here",
			"padding": "10px 16px 10px 16px",
			"name": "Text",
			"type": "text",
			"margin": "0px 0px 32px 0px",
			"min-height": "144px",
			"border-radius": "4px",
			"border-width": "2px",
			"width": "100%",
			"as": "textarea",
			"sm-margin": "0px 0px 20px 0px",
			"background": "rgba(255, 255, 255, 0)",
			"border-color": "rgba(228, 232, 236, 0.25)",
			"border-style": "solid",
			"color": "--primaryBlack"
		}
	},
	"box": {
		"kind": "Box",
		"props": {
			"display": "flex",
			"justify-items": "center",
			"align-items": "center",
			"justify-content": "center",
			"position": "relative"
		}
	},
	"button": {
		"kind": "Button",
		"props": {
			"border-radius": "4px",
			"padding": "9px 82px 9px 81px",
			"background": "--color-primaryBlack",
			"font": "--lead",
			"children": "Submit Enquiry"
		}
	}
};

const EmailForm = props => {
	const {
		override,
		children,
		rest
	} = useOverrides(props, overrides, defaultProps);
	const hoverVariants = {
		hover: {
			scale: 1.15,
			transition: {
				duration: 0.2 //yoyo: Infinity // This will create the waving effect

			}
		}
	};
	const clickVariants = {
		click: {
			scale: [1, 1.1, 1],
			opacity: [1, 0.8, 1],
			transition: {
				duration: 0.6
			}
		}
	};
	const [loading, setLoading] = useState(false);
	const [success, setSuccess] = useState(false);
	const [error, setError] = useState(false);

	const getRandomFun = () => {
		const arr = ["We promise it’ll be more fun than a rainy day in Brighton!", "We promise it’ll be as smooth as a cuppa with the Queen!", "Let's make it as brilliant as a sunny day in the UK!", "It's bound to be more exciting than a trip to the chippy!", "We’ll make it as delightful as a proper British cream tea!", "We’ll make it a piece of cake, and not the soggy bottom kind!"];
		if (arr.length === 0) return '';
		const randomIndex = Math.floor(Math.random() * arr.length);
		return arr[randomIndex].toString();
	};

	return <Box {...rest}>
		{!success && <>
			<Text {...override('HeadingText')} />
			<Text {...override('SubHeadingText')} />
			<Text {...override('FunPart')}>
				{getRandomFun()}
			</Text>
		</>}
		{success && <Text {...override('SuccessMessage')}>
			Enquiry has been sent successfully. We'll get back to you soon.
		</Text>}
		{loading && <Text {...override('LoadingMessage')}>
			Sending...
		</Text>}
		{error && <Text {...override('ErrorMessage')}>
			{error}
		</Text>}
		{!success && <PostmarkForm name="generalForm" onLoading={setLoading} onSuccess={setSuccess} onError={setError}>
			<Text {...override("text")} />
			<Input {...override("input")} />
			<Text {...override("text1")} />
			<Input {...override("input1")} />
			<Text {...override("text2")} />
			<Input {...override("input2")} />
			<Box {...override("box")}>
				<MotionButton variants={{ ...hoverVariants,
					...clickVariants
				}} whileHover="hover" whileTap="click" {...override("button")} />
			</Box>
			{children}
		</PostmarkForm>}
	</Box>;
};

Object.assign(EmailForm, { ...PostmarkForm,
	defaultProps,
	overrides
});
export default EmailForm;