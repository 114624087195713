import React, { useEffect, useRef } from 'react';
import { Box } from '@quarkly/widgets';
import atomize from "@quarkly/atomize";

const CookieDeclaration = props => {
	const boxRef = useRef(null);
	useEffect(() => {
		const script = document.createElement('script');
		script.id = 'CookieDeclaration';
		script.src = 'https://consent.cookiebot.com/db9e0027-4f0f-4227-8b8a-2d8c28d8667e/cd.js';
		script.type = 'text/javascript';
		script.async = true;

		if (boxRef.current) {
			boxRef.current.appendChild(script);
		}

		return () => {
			if (boxRef.current) {
				boxRef.current.removeChild(script);
			}
		};
	}, []);
	return <Box ref={boxRef} {...props}>
		            
		{
			/* The script will automatically inject the content here */
		}
		        
	</Box>;
};

export default atomize(CookieDeclaration)({
	name: "CookieDeclaration",
	effects: {
		hover: ":hover"
	},
	normalize: true,
	mixins: true,
	description: {
		en: "CookieDeclaration — a component to render Cookiebot CookieDeclaration script"
	},
	propInfo: {// Add any props descriptions here if needed
	}
});