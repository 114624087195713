import React, { useEffect } from "react";
import { useOverrides, Override } from "@quarkly/components";
import { Text, Box, Link, Icon, LinkBox, Section } from "@quarkly/widgets";
import WavedropperSignature from "./WavedropperSignature";
import TagManager from 'react-gtm-module';
import { FaTwitterSquare, FaGithub, FaYoutube } from "react-icons/fa";
const tagManagerArgs = {
	gtmId: 'GTM-58QKJTNZ'
};
const defaultProps = {
	"padding": "80px 0 50px 0",
	"quarkly-title": "Footer",
	"id": "footer",
	"background": "#000000"
};
const overrides = {
	"box": {
		"kind": "Box",
		"props": {
			"flex-direction": "row",
			"justify-content": "space-between",
			"width": "100%",
			"padding": "0 0px 0 0px",
			"sm-flex-direction": "column",
			"sm-padding": "0 0px 0 0px",
			"grid-template-columns": "repeat(2, 1fr)",
			"display": "flex"
		}
	},
	"box1": {
		"kind": "Box",
		"props": {
			"lg-margin": "0px 0px 0px 0px",
			"display": "flex",
			"sm-width": "100%",
			"flex-direction": "column",
			"padding": "0px 50px 0px 0px",
			"lg-padding": "0px 0 0px 0px",
			"sm-margin": "0px 0px 35px 0px",
			"font": "--base",
			"width": "60%"
		}
	},
	"text": {
		"kind": "Text",
		"props": {
			"margin": "0 0px 35px 0px",
			"font": "--base",
			"color": "#c3c8d0",
			"sm-text-align": "left",
			"sm-margin": "0 0px 0 0px",
			"lg-max-width": "640px",
			"children": "We're focusing on creating web applications and interactive tools. Services include developing educational apps\n\t\t\t\tand custom web solutions to enhance online engagement, with a focus on supporting startups and businesses in\n\t\t\t\testablishing their online presence.",
			"text-align": "justify"
		}
	},
	"wavedropperSignature": {
		"kind": "WavedropperSignature",
		"props": {
			"justify-self": "end",
			"bottom": "120px",
			"right": "60px",
			"position": "static"
		}
	},
	"box2": {
		"kind": "Box",
		"props": {
			"min-width": "100px",
			"min-height": "100px",
			"grid-template-columns": "repeat(2, 1fr)",
			"grid-gap": "54px",
			"lg-align-items": "start",
			"md-grid-template-columns": "repeat(3, 1fr)",
			"md-grid-gap": "36px 34px",
			"sm-grid-template-columns": "1fr",
			"sm-grid-gap": "16px 0",
			"justify-content": "flex-end",
			"sm-flex-direction": "column",
			"lg-justify-content": "flex-start",
			"quarkly-title": "Links",
			"justify-self": "end",
			"font": "--base",
			"grid-column": "1/3",
			"sm-display": "flex",
			"sm-align-items": "center"
		}
	},
	"box3": {
		"kind": "Box",
		"props": {
			"align-items": "flex-start",
			"margin": "0px 0px 0px 0",
			"lg-align-items": "flex-start",
			"justify-content": "flex-start",
			"display": "grid",
			"lg-flex-direction": "column",
			"lg-margin": "0px 0px 0px 0px",
			"flex-direction": "column",
			"align-content": "start",
			"grid-gap": "10px 0",
			"sm-justify-items": "center"
		}
	},
	"link": {
		"kind": "Link",
		"props": {
			"border-color": "--color-primary",
			"display": "flex",
			"font": "normal 400 16px/24px --fontFamily-googleRoboto",
			"margin": "0px 0 0px 0",
			"lg-border-width": "0px 0px 0px 2px",
			"text-decoration-line": "initial",
			"color": "#c3c8d0",
			"hover-color": "#ffffff",
			"letter-spacing": "1px",
			"href": "/about-us",
			"children": "About Us"
		}
	},
	"link1": {
		"kind": "Link",
		"props": {
			"margin": "0px 0 0px 0",
			"font": "normal 400 16px/24px --fontFamily-googleRoboto",
			"text-decoration-line": "initial",
			"color": "#c3c8d0",
			"hover-color": "#ffffff",
			"letter-spacing": "1px",
			"href": "/privacy",
			"children": "Privacy Policy"
		}
	},
	"link2": {
		"kind": "Link",
		"props": {
			"margin": "0px 0 0px 0",
			"hover-color": "#ffffff",
			"text-decoration-line": "initial",
			"color": "#c3c8d0",
			"font": "normal 400 16px/24px --fontFamily-googleRoboto",
			"letter-spacing": "1px",
			"href": "/cookies",
			"children": "Cookies"
		}
	},
	"link3": {
		"kind": "Link",
		"props": {
			"margin": "0px 0 0px 0",
			"hover-color": "#ffffff",
			"font": "normal 400 16px/24px --fontFamily-googleRoboto",
			"text-decoration-line": "initial",
			"color": "#c3c8d0",
			"display": "flex",
			"letter-spacing": "1px",
			"href": "/terms",
			"children": "Terms & Conditions"
		}
	},
	"box4": {
		"kind": "Box",
		"props": {
			"display": "flex",
			"justify-content": "space-between",
			"padding": "50px 0px 0px 0px",
			"border-color": "#232a44",
			"md-padding": "30px 0px 0px 0px",
			"lg-padding": "40px 0px 0px 0px",
			"height": "auto",
			"font": "--base",
			"md-flex-direction": "row",
			"md-align-items": "center",
			"sm-flex-direction": "column",
			"lg-align-items": "center",
			"align-items": "center"
		}
	},
	"text1": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 0px 0px",
			"font": "--base",
			"color": "#c3c8d0",
			"md-margin": "0px 0px 25px 0px",
			"children": "© 2024 WAVEDROPPER LTD. All rights reserved.",
			"sm-text-align": "center"
		}
	},
	"box5": {
		"kind": "Box",
		"props": {
			"display": "none",
			"grid-template-columns": "repeat(5, 1fr)",
			"grid-gap": "16px 24px",
			"md-align-self": "flex-start"
		}
	},
	"linkBox": {
		"kind": "LinkBox",
		"props": {
			"href": "/"
		}
	},
	"icon": {
		"kind": "Icon",
		"props": {
			"category": "fa",
			"icon": FaTwitterSquare,
			"size": "24px",
			"color": "#c3c8d0",
			"hover-color": "white",
			"transition": "background-color 1s ease 0s"
		}
	},
	"linkBox1": {
		"kind": "LinkBox",
		"props": {
			"href": "/"
		}
	},
	"icon1": {
		"kind": "Icon",
		"props": {
			"category": "fa",
			"icon": FaGithub,
			"size": "24px",
			"color": "#c3c8d0",
			"hover-color": "white",
			"transition": "background-color 1s ease 0s"
		}
	},
	"linkBox2": {
		"kind": "LinkBox",
		"props": {
			"href": "/"
		}
	},
	"icon2": {
		"kind": "Icon",
		"props": {
			"category": "fa",
			"icon": FaYoutube,
			"size": "24px",
			"color": "#c3c8d0",
			"hover-color": "white",
			"transition": "background-color 1s ease 0s"
		}
	}
};

const Footer = props => {
	const {
		override,
		children,
		rest
	} = useOverrides(props, overrides, defaultProps);
	useEffect(() => {
		TagManager.initialize(tagManagerArgs);
	}, []);
	return <>
		<style>
			{`
          a#CybotCookiebotDialogPoweredbyCybot,
          div#CybotCookiebotDialogPoweredByText {
            display: none;
          }

          #CookiebotWidget .CookiebotWidget-body .CookiebotWidget-main-logo {
            display: none!important; 
          }
					#CookiebotWidget #CookiebotWidget-buttons #CookiebotWidget-btn-change {
						background-color: #2A9D8F!important;
						border-color: #2A9D8F!important;
					}
					#CookiebotWidget .CookiebotWidget-body .CookiebotWidget-consents-list li.CookiebotWidget-approved svg {
    				fill: #2A9D8F!important;
					}
        `}
		</style>
		<Section {...rest}>
			<Override slot="SectionContent" justify-content="flex-start" />
			<Box {...override("box")}>
				<Box {...override("box1")}>
					<Text {...override("text")} />
				</Box>
				<Box {...override("box2")}>
					<Box {...override("box3")}>
						<Link {...override("link")} />
						<Link {...override("link1")} />
						<Link {...override("link2")} />
						<Link {...override("link3")} />
					</Box>
				</Box>
			</Box>
			<Box {...override("box4")}>
				<Text {...override("text1")} />
				<WavedropperSignature {...override("wavedropperSignature")} />
				<Box {...override("box5")}>
					<LinkBox {...override("linkBox")}>
						<Icon {...override("icon")} />
					</LinkBox>
					<LinkBox {...override("linkBox1")}>
						<Icon {...override("icon1")} />
					</LinkBox>
					<LinkBox {...override("linkBox2")}>
						<Icon {...override("icon2")} />
					</LinkBox>
				</Box>
			</Box>
			{children}
		</Section>
	</>;
};

Object.assign(Footer, { ...Section,
	defaultProps,
	overrides
});
export default Footer;