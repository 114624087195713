import React, { useState } from 'react';
import { useOverrides } from '@quarkly/components';
import { Box } from '@quarkly/widgets';
import { motion } from 'framer-motion';
import atomize from "@quarkly/atomize";
const MotionBox = motion(Box);
const defaultProps = {
	width: '100%',
	height: '100%',
	animationDuration: '5',
	animationScale: '1.05',
	animationTranslateY: '5%'
};
const overrides = {};

const WaverAnimation = ({
	animationDuration,
	animationScale,
	animationTranslateY,
	width,
	height,
	...props
}) => {
	const {
		override,
		children,
		rest
	} = useOverrides(props, overrides, defaultProps);
	const waveVariants = {
		animate: {
			scale: [1, parseFloat(animationScale), 1],
			translateY: ['0%', `-${animationTranslateY}`, '0%'],
			transition: {
				duration: parseFloat(animationDuration),
				repeat: Infinity,
				repeatType: 'mirror',
				ease: 'easeInOut'
			}
		}
	};
	return <MotionBox
		{...rest}
		variants={waveVariants}
		animate="animate"
		dragElastic={0.6}
		style={{
			width,
			height
		}}
	>
		      
		{children}
		    
	</MotionBox>;
};

const propInfo = {
	animationDuration: {
		title: 'Animation Duration',
		control: 'input',
		type: 'text',
		category: 'Animation',
		weight: 1
	},
	animationScale: {
		title: 'Animation Scale',
		control: 'input',
		type: 'text',
		category: 'Animation',
		weight: 1
	},
	animationTranslateY: {
		title: 'Animation Translate Y',
		control: 'input',
		type: 'text',
		category: 'Animation',
		weight: 1
	},
	width: {
		title: 'Width',
		control: 'input',
		type: 'text',
		category: 'Dimensions',
		weight: 1
	},
	height: {
		title: 'Height',
		control: 'input',
		type: 'text',
		category: 'Dimensions',
		weight: 1
	}
};
export default atomize(WaverAnimation)({
	name: "WaverAnimation",
	effects: {
		hover: ":hover"
	},
	normalize: true,
	mixins: true,
	description: {
		en: "WaverAnimation — a component that applies wave-like animation to its children"
	},
	propInfo,
	defaultProps
});